import fetchApi from './config'
import Cookies from 'utils/cookies'
import axios from 'axios'

let authUrl = process.env.REACT_APP_PORTAL_API

export const login = async ({ username, password }) => {
  return await fetchApi(process.env.REACT_APP_PORTAL_API + '/api/login', { username, password }, 'post')
}

export const logout = async () => {
  return await fetchApi(process.env.REACT_APP_PORTAL_API + '/api/logout', {}, 'post')
}

export const refreshToken = async () => {
  const refreshToken = await Cookies.getRefreshToken()

  if (!refreshToken) return Promise.reject('Refresh token not found')

  try {
    const res = await axios.post(
      `${authUrl}/api/token/refresh`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + refreshToken,
        },
      }
    )

    return res.data?.data
  } catch (error) {
    return null
  }
}

