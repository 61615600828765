import { createSlice } from '@reduxjs/toolkit'

const initFilter = {
  search: '',
  created_by: null,
  assignee: null,
  approve_by: null,
  category: null,
  store: null,
  'created_at[gte]': null,
  'created_at[lte]': null,
  'deadline[gte]': null,
  'deadline[lte]': null,
  per_page: 10,
  page: 1,
}

const initialState = {
  loading: false,
  filter: initFilter,
}

const ticketSlice = createSlice({
  initialState,
  name: 'ticket',
  reducers: {
    updateFilter: (state, action) => {
      return {
        ...state,
        filter: action.payload,
      }
    },
    resetilter: (state, action) => {
      return {
        ...state,
        filter: initFilter,
      }
    },
  },
})

export const { actions: ticketActions, reducer: ticketReducer } = ticketSlice
export const ticketSelector = (state) => state.ticket
