import { useSelector } from 'react-redux'
import { userSlector } from 'redux/slices/userSlice'

const useAuthorize = (roles) => {
  const user = useSelector(userSlector)

  if (!user?.isLoggedIn) return false

  let isAllow = false

  return isAllow
}

export default useAuthorize
