import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { userActions } from 'redux/slices/userSlice'
import LocalStorage from 'utils/localStorage'
import { useNavigate } from 'react-router-dom'
import * as roleService from 'services/role.service'
import moment from 'moment'
import Cookies from 'utils/cookies'

function useAppInit(setIsLoading) {
  const dispatch = useDispatch()

  useEffect(() => {
    const init = async () => {
      const token = await Cookies.getToken()

      if (token) {
        dispatch(
          userActions.getUserToken({
            onSuccess: () => {
              setIsLoading(false)
            },
            onError: () => {
              setIsLoading(false)
            },
          })
        )
      } else {
        setIsLoading(false)
      }
    }

    init()
  }, [])
}

export default useAppInit
